import React from 'react';

import { useErrorPage } from '../../api/hooks/useErrorPage.hook';
import { MetaTagsResolver } from '../../components/_shared/meta-tags-resolver/meta-tags-resolver';
import TextSection from '../../components/_shared/text-section/text-section';

const ErrorPage: React.FC & { dataHooks: any } = () => {
    const data = useErrorPage();

    if (data) {
        return (
            <div>
                <MetaTagsResolver tags={data.meta} />
                <TextSection
                    title={data?.title ?? ''}
                    description={data?.description ?? ''}
                />
            </div>
        );
    } else {
        return <div />;
    }
};

ErrorPage.dataHooks = [useErrorPage, ...MetaTagsResolver.dataHooks];

export default ErrorPage;
