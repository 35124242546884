import React, {ReactNode, useEffect, useRef, useState} from 'react';

import {useTypoContent} from '../../../api/hooks/useTypoContent.hook';
import {TypoElementProgressReader} from '../../../api/models/typo/typo-element-progress_reader';
import {TypoContentElement} from '../../../api/models/typo-content-element';
import {TypoService} from '../../../api/services/typo.service';
import styles from './progress_reader.module.scss';

const ProgressReader = (props: TypoElementProgressReader): JSX.Element => {

    const { items, maxWidth, withoutBar } = props;
    
    if (!items) {
        return <div/>;
    }

    const typoService = new TypoService(useTypoContent());

    const [readingProgress, setReadingProgress] = useState(0);
    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const calculateReadingProgress = () => {
            if (!targetRef.current) return;

            const targetElement = targetRef.current;
            const targetRect = targetElement.getBoundingClientRect();
            const targetTop = targetRect.top;
            const targetBottom = targetRect.bottom;
            const isInViewport = targetTop <= 0 && targetBottom >= 0;
            let progress = Math.floor((Math.abs(targetTop) / targetElement.offsetHeight) * 100);

            if (isInViewport) {
                if (progress <= 0) {
                    progress = 1;
                } else if (progress > 100) {
                    progress = 100;
                }
                setReadingProgress(progress);
            } else if (targetTop >= 0) {
                setReadingProgress(0);
            } else if (targetBottom < 0) {
                setReadingProgress(100);
            }
        };

        const scrollListener = () => {
            calculateReadingProgress();
        };

        document.addEventListener('scroll', scrollListener);
        return () => {
            document.removeEventListener('scroll', scrollListener);
        };
    }, []);

    const progressBarStyles = {
        width: `${readingProgress}%`,
    };

    return (
        <div className={styles['reading-progress-container']}>
            {!withoutBar && (
                <div className={styles['reading-progress']} style={progressBarStyles}></div>
            )}
            <div className={styles['width-'+maxWidth]}>
                <div ref={targetRef} className={styles['content']}>
                    {
                        Object.keys(items).map((key: string) => {
                            const element = items[key] as TypoContentElement;
                            return <div key={key}>{typoService.createContentElement(element) as ReactNode}</div>
                        })
                    }
                </div>
            </div>
        </div>
    );
};

ProgressReader.defaultProps = {};

export { ProgressReader };
