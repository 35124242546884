import { TextSectionProps } from '../../components/_shared/text-section/text-section';
import { StrapiMeta } from '../models/strapi/strapi-meta';
import { useLanguageDependentDataSSr } from './useLanguageDependentDataSsr.hook';

export const useErrorPage = useLanguageDependentDataSSr<ErrorPageData>(
    'error-page'
);

interface ErrorPageData extends TextSectionProps {
    meta: StrapiMeta;
}