import { Worker } from '@react-pdf-viewer/core';
import { useRouter } from 'next/dist/client/router';
import React, { ReactNode, useEffect, useState } from 'react';

import { useErrorPage } from '../../api/hooks/useErrorPage.hook';
import { getTypoData, useTypoContent } from '../../api/hooks/useTypoContent.hook';
import { StrapiImage } from '../../api/models/strapi/strapi-image';
import { StrapiMeta } from '../../api/models/strapi/strapi-meta';
import { Typo, TypoLocalizations } from '../../api/models/typo';
import { TypoContentElement } from '../../api/models/typo-content-element';
import { TypoMeta } from '../../api/models/typo-meta';
import { TypoService } from '../../api/services/typo.service';
import ContactForm from '../../components/_shared/contact-form/contact-form';
import { Footer } from '../../components/_shared/footer/footer';
import { DefaultHeader } from '../../components/_shared/header/default-header/default-header';
import { HeaderFooterWrapper } from '../../components/_shared/header-footer-wrapper/header-footer-wrapper';
import { MetaTagsResolver } from '../../components/_shared/meta-tags-resolver/meta-tags-resolver';
import { Typography } from '../../components/_shared/typography/typography';
import { ArticlePageTempHeader } from '../../components/article-page/article-page-temp-header/article-page-temp-header';
import { RegisterFormLight } from '../../components/register/register-form/register-form-light';
import {ProgressReader} from '../../components/typo/progress_reader/progress_reader';
import MyApp from '../../pages/_app';
import { RootState, store } from '../../redux';
import { ArticlePageTypes } from '../../utils/_enums/article-page-types';
import { TypographyVariantsEnum } from '../../utils/_enums/typeography-variants.enum';
import { useAppSelector } from '../../utils/_hooks/hooks';
import { LanguageSelectorOptions } from '../../utils/_models/languageSelectorOptions';
import ErrorPage from '../error-page/error-page';
import styles from './typo-page.module.scss';
import {TypoElementProgressReader} from "../../api/models/typo/typo-element-progress_reader";

export const TypoPage: React.FC & {
    dataHooks: any;
} = () => {

    const router = useRouter();
    const [initComplete, setInitComplete] = useState(false);
    const [loginAuthDone, setLoginAuthDone] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorData, setErrorData] = useState<Typo | null>(null);
    const pending = useAppSelector((state: RootState) => state.authentification.pending);
    const typoContent = useTypoContent();

    useEffect(() => {
        if (pending && !loginAuthDone) {
            setLoginAuthDone(true);
        }

        // Reload page and its content on login
        if (loginAuthDone && store.getState().authentification.jwt) {
            const { pathname, asPath, query } = router;
            const lang = router.locale ? router.locale.toLowerCase() : 'en';
            router.push({ pathname, query }, asPath, { locale: lang });
        }

        if (!initComplete) {
            setInitComplete(true);
        }
    }, [pending, store.getState().authentification.jwt]);

    // Unset errorData on language change
    useEffect(() => {
        if (errorData) {
            setErrorData(null);
        }
    }, [router.locale, router.query]);

    // Errorcode handling (get content from Typo3 error pages)
    const getTypoPageData = (page: string) => {
        getTypoData(router.locale, { typoPage: [page] }).then((resp: Typo | number | undefined) => {
            if (resp && typeof resp != 'number') {
                setErrorData(resp);
                setLoading(false);
            }
        }).catch()
    }
    if (!errorData && (!typoContent || typeof typoContent === 'number' || !typoContent.id)) {
        if (typoContent === 403) {
            getTypoPageData('403');
        } else {
            getTypoPageData('404');
        }
    }

    const data = typoContent && typeof typoContent !== 'number' ? typoContent : errorData as Typo;
    const typoService = new TypoService(data);
    const pageContent = typoService.getPageContent() ?? [];
    const type = ArticlePageTypes.ARTICLE_PAGE;
    const localizations = typoService.getLocalizations();
    const firstContentElement = pageContent[0] as TypoContentElement;
    const stageElement = typoService.createStageElement(firstContentElement) as ReactNode;
    if (typeof typoContent !== 'number' && loading) {
        setLoading(false);
    }

    // Page background (default: white, inverted: black)
    const inverted = data && data.creatokia && data.creatokia.style ? data.creatokia.style === 'inverted' : false;

    // Language switcher
    const nextLocalizations: LanguageSelectorOptions[] = [];
    if (typeof localizations !== 'boolean') {
        const currentLocale = !router.locale || router.locale === 'catchAll' ? 'en' : router.locale;
        localizations.map((lang: TypoLocalizations) => {
            // Removes the active language from the select menu
            if (currentLocale == lang.twoLetterIsoCode) {
                return true;
            }

            if (nextLocalizations) {
                // Adjustment because EN has a different ID in Strapi
                const id = lang.twoLetterIsoCode === 'en' ? 34 : lang.languageId;
                nextLocalizations.push({
                    id: id, locale: lang.twoLetterIsoCode
                });
            }
        });
    }

    // MetaTagsResolver conversion
    const meta: TypoMeta = data && typeof data != 'number' && data.meta ? data.meta : {} as TypoMeta;
    const newsCE = typoService.getNewsContent();
    const newsTitle = newsCE?.news?.detail?.metaData?.alternativeTitle != '' ? newsCE?.news?.detail?.title : newsCE?.news?.detail?.title;
    const newsDesc = newsCE?.news?.detail?.metaData?.description != '' ? newsCE?.news?.detail?.metaData?.description : newsCE?.news?.detail?.teaser;
    const newsImages = newsCE?.news?.detail?.falRelatedFiles;
    const metaImage = newsImages ? newsImages[0]?.images.defaultImage : undefined;
    const chosenImage = metaImage?.publicUrl ?? meta.ogImage?.publicUrl;

    const metaOgImage = {
        url: chosenImage ?? 'https://creatokia-rs-test.s3.eu-central-1.amazonaws.com/backend/crt_socialMedia_fallbackImage.jpg',
        alternativeText: '',
        height: '',
        width: '',
    };

    const strapiMeta: StrapiMeta = {
        metaTagDescription: newsDesc ?? meta.description,
        metaTagKeywords: newsCE?.news?.detail?.metaData?.keywords ?? meta.keywords,
        metaTagTitle: newsTitle ?? meta.title,
        metaTagCanonical: newsCE?.news?.detail?.canonical ?? meta.canonical,
        metaOgTitle: newsTitle ?? meta.ogTitle,
        metaOgDescription: newsDesc ?? meta.ogDescription,
        MetaOgImage: metaOgImage as unknown as StrapiImage,
        metaTwitterTitle: newsTitle ?? meta.twitterTitle,
        metaTwitterDescription: newsDesc ?? meta.twitterDescription,
        metaTwitterImage: metaImage?.publicUrl ?? meta.twitterImage?.publicUrl,
        metaTwitterSite: meta.twitterCard,
        metaNoFollow: meta.robots?.noFollow,
        metaNoIndex: meta.robots?.noIndex
    };

    let progressReaderCollect = false;
    function setProgressReaderCollect(assign: boolean) {
        progressReaderCollect = assign;
    }

    let progressReader: TypoElementProgressReader;
    function setProgressReader(assign: TypoElementProgressReader) {
        progressReader = assign;
    }

    let progressReaderItems: TypoContentElement[] | [] = [];
    function setProgressReaderItems(assign: TypoContentElement[] | []) {
        progressReaderItems = assign;
    }

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div className={`
                typo-page
                ${inverted ? `${styles['inverted']} inverted` : ''}
                ${data?.creatokia?.page ? styles[`${data?.creatokia?.page}`] : ''}
            `}>
                <HeaderFooterWrapper
                    header={
                        <DefaultHeader
                            forStaticPage={true}
                            pageType={type}
                            availableLanguages={nextLocalizations}
                            typoService={typoService}
                        />
                    }
                    stage={stageElement ? <div
                        className={styles['stage-container']}>{stageElement}</div> : undefined}
                    footer={
                        <Footer
                            typoService={typoService}
                        />
                    }
                >
                    {pageContent && pageContent.length > 0 ? (
                        <div className={styles['typo-page']}>
                            <MetaTagsResolver tags={strapiMeta} articlePageType={type} isTypoPage={true} />
                            {
                                Object.keys(pageContent).map((key: string) => {
                                    const element = pageContent[key] as TypoContentElement;
                                    if (element.type === 'progressReaderStart' ||
                                        element.type === 'progressReaderEnd' ||
                                        progressReaderCollect) {

                                        if (element.type === 'progressReaderStart') {
                                            setProgressReaderCollect(true);
                                            setProgressReader(element.content as TypoElementProgressReader);
                                        } else if (element.type === 'progressReaderEnd' || key >= pageContent.length) {
                                            const items = progressReaderItems;
                                            setProgressReaderCollect(false);
                                            setProgressReaderItems([]);
                                            return (
                                                <div key={key}>
                                                    <ProgressReader
                                                        items={items}
                                                        maxWidth={progressReader.maxWidth}
                                                        withoutBar={progressReader.withoutBar}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            setProgressReaderItems([...progressReaderItems, element]);
                                        }
                                    } else {
                                        return <div
                                            key={key}>{typoService.createContentElement(element) as ReactNode}</div>
                                    }
                                })
                            }
                        </div>
                    ) : (
                        <div>
                            {loading ? (
                                <div className={styles['loading']}>
                                    <div className={styles['loading-label']}>
                                        <Typography variant={TypographyVariantsEnum.HEADING3_BOLD} inverted={inverted}>
                                            Loading
                                        </Typography>
                                    </div>
                                    <div className={styles['loading-content']}>
                                        <div className={styles['loading-dot-elastic']} />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {typeof typoContent == 'number' && <ErrorPage />}
                                </>
                            )}
                        </div>
                    )}
                </HeaderFooterWrapper>
            </div>
        </Worker>
    );
};

TypoPage.dataHooks = [
    useTypoContent,
    useErrorPage,
    ...MyApp.dataHooks,
    ...ContactForm.dataHooks,
    ...HeaderFooterWrapper.dataHooks,
    ...ArticlePageTempHeader.dataHooks,
    ...RegisterFormLight.dataHooks,
    ...MetaTagsResolver.dataHooks
];
